import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionTitle from "../components/sectiontitle.jsx";
import Section from "../components/section.jsx";
import OtherResume from "../components/OtherResume";

import NS from "../images/portfolio/neoscroll/neoscroll-square.png";
import LD from "../images/portfolio/lenderdb/lenderdb-square.png";
import AD from "../images/portfolio/adhdpro/adhdpro-square.png";
import C3D from "../images/portfolio/cognitive3d/cognitive3d-square.png";
import WP from "../images/portfolio/wildcardpeople/wildcardpeople-square.png";
import FS from "../images/portfolio/foster/foster-square.png";

function PortfolioPage({ data }) {
  // const posts = data.allMarkdownRemark.edges
  return (
    <Layout currentPage={[{ title: `Portfolio`, url: `portfolio` }]}>
      <SEO
        keywords={[
          `Robert Merki`,
          `Robmerki`,
          `Rob Merki`,
          `@robmerki`,
          `Merki.ca`,
        ]}
        title="Portfolio"
      />

      <Section>
        <SectionTitle title="Current" />

        <div className="grid grid-cols-3 gap-5 md:gap-7 mt-2 mb-4 text-center">
          <Link to="/portfolio/foster">
            <img
              alt="Foster logo"
              className="mb-2 border border-gray-200 shadow-md hover:shadow-lg hover:opacity-90"
              src={FS}
            ></img>
            <div className="text-sm text-slate-600 font-bold">
              Computer Systems
            </div>
            <div className="text-sm text-slate-600">2021 — Present</div>
          </Link>
          <Link to="/portfolio/wildcardpeople">
            <img
              alt="Wildcard People logo"
              className="mb-2 border border-gray-200 shadow-md hover:shadow-lg hover:opacity-90"
              src={WP}
            ></img>
            <div className="text-sm text-slate-600 font-bold">Side-Project</div>
            <div className="text-sm text-slate-600">2021 — Present</div>
          </Link>
        </div>

        <SectionTitle title="Past" />
        <div className="grid grid-cols-3 gap-5 md:gap-7 mt-2 mb-4 text-center">
          <Link to="/portfolio/neoscroll">
            <img
              alt="Neoscroll logo"
              className="mb-2 border border-gray-200 shadow-md hover:shadow-lg hover:opacity-90"
              src={NS}
            ></img>
            <div className="text-sm text-slate-600 font-bold">Side-Project</div>
            <div className="text-sm text-slate-600">2021</div>
          </Link>
          <Link to="/portfolio/lenderdb">
            <img
              alt="LenderDB logo"
              className="mb-2 border border-gray-200 shadow-md hover:shadow-lg hover:opacity-90"
              src={LD}
            ></img>
            <div className="text-sm text-slate-600 font-bold">Side-Project</div>
            <div className="text-sm text-slate-600">2021</div>
          </Link>
          <Link to="/portfolio/adhdpro">
            <img
              alt="ADHD Pro logo"
              className="mb-2 border border-gray-200 shadow-md hover:shadow-lg hover:opacity-90"
              src={AD}
            ></img>
            <div className="text-sm text-slate-600 font-bold">Author</div>
            <div className="text-sm text-slate-600">2020</div>
          </Link>
          <Link to="/portfolio/cognitive3d">
            <img
              alt="Cognitive3D logo"
              className="mb-2 border border-gray-200 shadow-md hover:shadow-lg hover:opacity-90"
              src={C3D}
            ></img>
            <div className="text-sm text-slate-600 font-bold">
              Director of Product
            </div>
            <div className="text-sm text-slate-600">2016 — 2020</div>
          </Link>
        </div>

        <OtherResume />
      </Section>
    </Layout>
  );
}

export default PortfolioPage;
