import React from "react";

export default function OtherResume() {
  return (
    <div>
      {Object.keys(myjobs).map((timeframe, i) => (
        <div className="" key={`category_${i}`}>
          <h3 className="mt-6 mb-1 text-sm tracking-wide uppercase text-slate-400">
            {timeframe}
          </h3>
          {myjobs[timeframe].map((job, x) => (
            <a
              className={`flex group items-center mb-1 hover:text-blue-500 text-blue-700 py-1 ${
                job.url ? `cursor-pointer` : `cursor-default`
              }`}
              href={job.url ? job.url : null}
              key={`work_${i}_${x}`}
              target="_blank"
            >
              <div className="flex-shrink-0 border-slate-400 border border-slate-200 rounded-md shadow-sm bg-white group-hover:bg-white transition-all group-hover:filter-none group-hover:shadow-md ">
                <img className="h-[50px] w-[50px] " src={job.img} />
              </div>

              <div className="flex-grow ml-4">
                <span className="block no-underline leading-tight font-medium">
                  {job.title} {job.url && <LinkIcon />}
                </span>
                <span className="block mb-1 text-sm tracking-tighter leading-tight opacity-75">
                  {job.description}
                </span>
              </div>
            </a>
          ))}
          <div className="mb-6"></div>
        </div>
      ))}
    </div>
  );
}

const myjobs = {
  // "Currently Doing": [
  //   {
  //     "title": "Robert Merki Consulting",
  //     "description": "Consultant - Helping businesses build & ship beautiful web apps",
  //     "url": "",
  //     "img": "img/logos/consulting.png",
  //   },
  //   {
  //     "title": "Neoscroll",
  //     "description": "Project - Minimalistic community for questions & answers.",
  //     "url": "https://neoscroll.com/",
  //     "img": "img/logos/neoscroll.png",
  //   },
  //   {
  //     "title": "LenderDB",
  //     "description": "Project - Marketplace for alternative financing",
  //     "url": "https://lenderdb.com/",
  //     "img": "img/logos/lenderdb.png",
  //   },
  //   {
  //     "title": "ADHD Pro",
  //     "description": "Author - Productivity for Adults with ADHD",
  //     "url": "https://adhdpro.xyz/",
  //     "img": "img/logos/adhd-pro.png",
  //   },
  // {
  //   "title": "Robert Merki Consulting",
  //   "description": "Consultant - Helping businesses build & ship beautiful web apps",
  //   "url": "",
  //   "img": "img/logos/consulting.png",
  // },
  // {
  //   "title": "Cognitive3D",
  //   "description": "Director of Product -  Virtual Reality Analytics",
  //   "url": "https://cognitive3d.com/",
  //   "img": "img/logos/cognitive3d.png",
  // },
  // ],
  "Other work": [
    {
      title: `ABCs of ADHD`,
      description: `Co-host - Podcast for Adults with ADHD`,
      url: `https://link.chtbl.com/ARx4tY8k`,
      img: `/img/logos/abcs-of-adhd.png`,
    },
    {
      title: `Awake Labs`,
      description: `Product Manager - IoT for Stress Management`,
      url: `http://awakelabs.com/`,
      img: `/img/logos/awake-labs.png`,
    },
    {
      title: `Startup Storm`,
      description: `Co-Founder - a UBC club for tech startups.`,
      url: `https://www.nwplus.io/`,
      img: `/img/logos/startupstorm.png`,
    },
    {
      title: `Mobify`,
      description: `Marketing Coorindator`,
      url: `https://www.mobify.com/`,
      img: `/img/logos/mobify.png`,
    },
    {
      title: `UBC Sauder School of Business`,
      description: `Student`,
      url: `http://www.sauder.ubc.ca/`,
      img: `/img/logos/sauder.png`,
    },
  ],
};

const LinkIcon = () => (
  <span>
    <svg
      className="h-4 w-4 mb-1 opacity-80 inline-block"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
    </svg>
  </span>
);
